<template>
  <div>
    <!-- <el-carousel :height="imgHeight + 'px'">
      <el-carousel-item v-for="item in 4" :key="item">
        <img
          ref="imgHeight"
          style="width: 100%"
          src="../assets/banner.png"
          mode="widthFix"
        />
      </el-carousel-item>
    </el-carousel> -->
     <img class="banner" style="width: 100%" :src="bander" alt="" />
    <div class="box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/heatsearch' }"
          >产品服务</el-breadcrumb-item
        >

        <el-breadcrumb-item :to="{ path: '/dangjian',query: {lei: deatileobj.cate_id} }"
          >产品列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>产品详情</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 5G及通讯实训室建设方案 -->
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;
          margin-bottom: 48px;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
         {{deatileobj.product_name}}
        </div>
      </div>
      <div
      v-html="deatileobj.content"
        style="
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
		 margin-bottom: 48px;
        "
      >
     
      </div>
	  <!-- <img style="width: 1200px;
height: 548px;" :src="deatileobj.image" alt=""> -->
    </div>
  </div>
</template>
<script>
import url from "../request/api";
export default {
  data() {
    return {
        id:false,
      imgHeight: "",
      imgurl: url.baseUrl,
      deatileobj:{},
      bander:[]
   
    };
  },
  mounted() {
this.banderlist()
     this.id=this.$route.query.detailsid
    if(this.id){
      this.deatile()
    }
    this.get_banner();
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener(
      "resize",
      () => {
        this.get_banner();
      },
      false
    );
  },
  methods: {
     banderlist(){
  this.$https
          .get(`/api/index/banner?name=product_banner`, )
          .then((res) => {
            
            console.log(res);
  // res.data.forEach((element,index) => {
  //         res.data[index] = this.imgurl+element
  //       });
            this.bander = res.data;
        });
    },
    deatile() {
       this.$https
        .get(`/api/product/product_details?id=${this.id}`, { id: this.id })
        .then((res) => {
          // res.data.about.image=this.imgurl+res.data.about.image
          console.log(res);
          res.data.image=this.imgurl+ res.data.image
          this.deatileobj=res.data
        });
    },
    trage(item) {
      this.chooseList = [];
      if (this.chooseList.indexOf(item) == -1) {
        this.chooseList.push(item);
      } else {
        this.chooseList.splice(this.chooseList.indexOf(item), 1);
      }
    },
    changenav(path, index) {
      this.$router.push({
        path: path,
      });
    },
    get_banner() {
      //获取到图片数据之后
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
        this.imgHeight = (document.body.clientWidth * 480) / 1920;
      });
    },
  },
  destroyed() {
    //切换页面后销毁监听窗口事件
    window.removeEventListener("resize", this.get_banner, false);
  },
};
</script>
<style scoped>
.trage {
  width: 330px;
  height: 58px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ba1c2b;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ba1c2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  margin-bottom: 80px;
}
.trages {
  background: #ba1c2b;
  color: #fff;
}
.box {
  width: 1300px;
  margin: 56px auto;
}
.s11 {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.left {
  width: 740px;
}

.left .list {
  width: 740px;
  height: 133px;
  cursor: pointer;
  margin-bottom: 48px;
}

.left .list:hover .info .title {
  color: #0049c2;
}

.left .list .img {
  width: 240px;
  height: 133px;
  overflow: hidden;
}

.left .list .img img {
  width: 240px;
}

.left .list .info {
  width: 480px;
}

.left .list .info .title {
  font-size: 20px;
  color: #222222;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.left .list .info .p {
  height: 42px;
  font-size: 16px;
  color: #666666;
  margin: 16px 0 23px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.left .list .info .time {
  font-size: 14px;
  color: #999999;
}

.right {
  width: 440px;
  background: #fbfbfc;
  box-sizing: border-box;
  padding: 24px;
}

.right .titles {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 10px;
}

.right .list {
  height: 48px;
  cursor: pointer;
}

.right .list:hover .title {
  color: #0049c2;
}

.right .list .indexs {
  width: 30px;
  font-size: 20px;
  color: #999999;
  text-align: center;
}

.right .list .title {
  width: 270px;
  font-size: 16px;
  color: #333333;
  margin: 0 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.right .list .num {
  font-size: 16px;
  color: #999999;
  margin-left: 5px;
}
</style>